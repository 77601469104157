const HomePage=()=>{
    return (
      <div className="Home">
        <div className="contacts">
            <table className=" table-borderless text-black">
                    <tr>
                        <td className='text-black text-end'>Phone <i className="fas fa-phone-alt"></i></td>
                        <td><a target="_blank" rel="noreferrer" className='text-black' href="tel:+959662486582">+959662486582</a></td>
                    </tr>
                    <tr>
                        <td className='text-black text-end'>Email <i className="fas fa-envelope-open"></i></td>
                        <td><a target="_blank"  rel="noreferrer" className='text-black' href="mailto:pyaephyohlaing2kk3@gmail.com">pyaephyohlaing2kk3<br/>@gmail.com</a></td>
                    </tr>
                    <tr>
                        <td className='text-black text-end'>Facebook <i className="fab fa-facebook"></i></td>
                        <td><a target="_blank"  rel="noreferrer" className='text-black' href="https://www.facebook.com/pyaephyo.hlaing.007">Pyare Phyoe Hlaing</a></td>
                    </tr>
                    <tr>
                        <td className='text-black text-end'>Github <i className="fab fa-github"></i></td>
                        <td><a target="_blank" rel="noreferrer" className='text-black' href="https://github.com/pyaephyo11979">pyaephyo11979</a></td>
                    </tr>
                    <tr>
                        <td className='text-black text-end'>LinkedIn <i className="fab fa-linkedin"></i></td>
                        <td><a target="_blank" rel="noreferrer"  className='text-black'href="https://www.linkedin.com/in/pyae-phyo-hlaing-66b0961aa/">Pyae Phyo Hlaing</a></td>
                    </tr>
                    <tr>
                        <td className='text-back text-end '>Instagram <i className="fab fa-instagram"></i></td>
                        <td><a target="_blank" rel="noreferrer" className='text-black' href="https://www.instagram.com/pyae_p_h/">pyae_p_h</a></td>
                    </tr>
            </table>
        </div>
        <div className="buttonArea mt-0 mt-lg-auto">
            <button className="btn btn-primary rounded-5 m-2"><a href="https://pyaephyo.me" target="_blank" rel="noreferrer" className="text-decoration-none">Visit Website <i className='fas fa-globe'></i></a></button>
            <button className="btn btn-primary rounded-5 m-2"><a href="tel:+959662486582" target="_blank" rel="noreferrer" className="text-decoration-none">Call Now <i className="fas fa-phone"></i></a></button>
        </div>
      </div>
    )
}
export default HomePage