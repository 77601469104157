const Header=()=>{
    return(
        <div className="Header ">
            <div className="Textarea ">
                <h2 className="headerText">Pyae Phyo Hlaing</h2>
                <div className="divider"></div>
                <p className="headerSubtext">FrontEnd Web Developer</p>
                <p className="headerSubtext">UI/UX Desginer</p>
            </div>
            <div className="headerPhotoArea ">
                <img src='./pf.JPG' className="ms-auto" alt='img'/>
            </div>
        </div>
    )
}
export default Header